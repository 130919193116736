@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap');

.nav-title{
    font-size: 22px;
    line-height: 26px;
    font-weight: 400;
    color: white;
}

nav .nav-links a{
  font-weight: 600;
  color: #555;
}

nav .nav-links a:hover{
  color: #000;
}

.sm-toggler{
    display: none;
}

.nav-buttons .btn-main{
  z-index: 99;
  position: relative;
}

@media only screen and (max-width: 768px) {

    nav .btn-main,nav .btn-main-alt{
      padding: 0.65rem 1.35rem;
      font-size: 15px;
      border-radius: 5px;
    }

    .sm-toggler{
        overflow-y: scroll;
        /* display: flex; */
        width: 100%;
        padding-bottom: 100px;
    }
}