.carousel-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.slide {
  padding: 10px;
  text-align: center;
}

.slide img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}


.slick-track
{
    display: flex !important;
}

.slick-slide
{
    display: flex !important;
    height: inherit !important;
}
.slick-slide>div
{
  width: 100%;
  display: flex !important;
  height: inherit !important;
}
.slick-list {
  margin: 0 -10px;
  /* display: flex; */
}
.slick-slide{
  height: 100%;
  flex: 1;
  padding: 0 10px;
}

.slick-dots {
  text-align: right !important;
}
.slick-dots li {
  margin: 0px !important;
}

.slick-prev, .slick-next{
  z-index: 99;
  top: 105% !important;
}

.slick-prev:before, .slick-next:before {
  font-family: monospace !important;
  font-size: 36px;
  background: #00E07E;
  color: black;
  padding: 0px 10px;
  border-radius: 6px;
  opacity: .55;
}

.slick-prev {
  left: 0px !important;
}

.slick-next{
  left: 50px !important;
}

.slick-dots li button:before {
  color: #00E07E;
  font-size: 10px;
}

.slick-dots li.slick-active button:before {
  opacity: .75;
  color: #00E07E;
}

.custom-prev-arrow, .custom-next-arrow {
  font-size: 46px;
  position: absolute;
  top: 40%;
  z-index: 99;
  display: block;
  width: 35px !important;
  height: 35px !important;
  /* transform: translate(0, -50%); */
  cursor: pointer;
  color: #AFB7FF;
  border: none;
  outline: none;
  background: transparent;
}

.custom-prev-arrow {
  left: -60px;
}

.custom-next-arrow {
  right: -45px;
}

.project{
  border-radius: 10px;
  background: #111111;
  transition: .25s ease;
  margin: 15px 0px;
  display: flex !important;
  flex-direction: column;
  position: relative;
  z-index: 11;
}

.project.Apps{
  flex-direction: row;
}

.project .main-image{
  border-radius: 10px 10px 10px 10px;
  margin-bottom: 0px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  border: 1px solid #111;
}

.project .sec-image{
  transition: 0.5s;
  border-radius: 10px 10px 10px 10px;
  margin-bottom: 0px;
  width: 50%;
  position: absolute;
  z-index: 10;
  right: 13px;
  top: 32%;
  object-fit: cover;
  object-position: top;
  border: 1px solid #111;
}

.project .sec-image:hover{
  top: 0%;
  right: 0px;
  width: 100%;
  border: 0px solid #111;
}

.project.Websites .sec-image{
  width: 40%;
  top: 30%;
  max-height: 68%;
}

.project.Passion .sec-image{
  width: 40%;
  max-height: 68%;
}

.project.Websites .sec-image:hover{
  height: 100%;
  max-height: 100%;
  top: 0%;
  right: 0px;
  width: 100%;
  animation: scrollsite 5s forwards;
  animation-delay: 1s;
}

@keyframes scrollsite {
  from {object-position: top;}
  to {object-position: bottom;}
}

.project.Websites .sec-image:hover + .project-images {
  position: static !important;
}

.project .project-images .main-image:hover + .sec-image{
  right: -70%;
}

.project > .project-info{
  background: #111111;
  /* z-index: 9; 
  position: relative;
  */
  padding: 1.5rem 2rem;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 0px 0px 10px 10px;
  transition: .5s;
}

.project:hover{
  --tw-shadow-color: #00E07E;
  --tw-shadow: var(--tw-shadow-colored);
  --tw-shadow-colored: 0 0 20px 0 var(--tw-shadow-color); /* Updated values */
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #00E07E), var(--tw-ring-shadow, 0 0 #00E07E), var(--tw-shadow);
  
}

.project h2{
  font-size: 22px;
  color: white;
  font-weight: 500;
  letter-spacing: 1.2px;
}

.project p{
  font-size: 16px;
  color: #999;
  letter-spacing: .75px;
  margin-bottom: 20px;
}

.project.Websites p{
  width: 60%;
}

.project a{
  font-size: 14px;
  letter-spacing: .5px;
  color: #00E07E;
}

.project a:hover{
  color: #00B868;
}


@media only screen and (max-width: 768px) {
  .project.Apps{
    flex-direction: column;
  }

  .slick-dots{
    display: none !important;
  }

  .project .sec-image{
    top: 28%;
  }
}