section{
    position: relative;
    z-index: 50;
    overflow: hidden;
}

.fullpage-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.section {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: scroll;
}

/* Apply active style to the currently visible section */
.section.active {
  z-index: 1;
}

/* Navigation dots */
.nav-dots {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-dot {
  width: 10px;
  height: 10px;
  background-color: #ccc;
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.nav-dot.active {
  background-color: #333;
}